* {
  scrollbar-width: thin;
}

.page-form {
  max-width: 850px;
  margin: 0 auto;
}

.page-spin {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-generator {
  padding: 0 0 25px 0;
}

/* utilities classes */

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}
.gap-8 {
  gap: 8px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.vh-100 {
  height: 100vh;
}

.fw-500 {
  font-weight: 500;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mw-300 {
  max-width: 300px;
}

.text-nowrap {
  white-space: nowrap;
}

.pb-15 {
  padding-bottom: 15px;
}

.w-100 {
  width: 100%;
}

.ant-typography {
  margin: 0 !important;
}

p {
  margin: auto !important;
}

/* chart */
.apexcharts-canvas .apexcharts-xaxistooltip {
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.apexcharts-canvas .apexcharts-xaxistooltip:before {
  border-bottom-color: transparent;
}

.apexcharts-canvas .apexcharts-xaxistooltip:after {
  border-bottom-color: transparent;
}

.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-series-group {
  padding: 0;
}
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-series-group
  .apexcharts-tooltip-marker {
  margin-right: 4px;
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light {
  background: #111827;
  color: #ffffff;
  border: none;
  width: 90px;
  height: 60px;
  padding: 8px 12px;
  overflow: visible !important;
  border-radius: 8px !important;
}

.apexcharts-tooltip {
  transform: translate(-43px, -45px) !important;
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #111827 transparent transparent transparent;
}

.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  border: none;
  background: #111827;
  font-weight: 500;
  color: #9ca3af;
  padding: 0;
  margin: 0;
}

.ant-select-lg .ant-select-selector {
  padding: 8px 12px;
}

.ant-btn-lg {
  padding: 8px 12px;
}

.ant-pro-layout .ant-pro-sider-logo {
  border-block-end: 0;
  padding-inline: 24px;
  padding-top: 32px;
}
.ant-pro-layout .ant-pro-sider-logo > a > h1 {
  font-weight: "bold";
  color: #000000;
  margin-left: 8px;
}

.ant-page-header-no-children {
  height: 0;
}

.ant-pro-page-container-children-container {
  padding: 0;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  align-items: center;
}

a.cke_dialog_ui_button_ok {
  background-color: blue !important;
  color: white !important;
}
a.cke_dialog_ui_button {
  border-radius: 4px !important;
  width: 100px !important;
  padding: 8px 12px !important;
}


.ant-tour-content .ant-tour-inner{
  display: flex;
  min-height: 150px;
  flex-direction: column;
  justify-content: space-between;
}

.ant-tour .ant-tour-inner .ant-tour-footer {
  gap: 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}